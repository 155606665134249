export default {
  extendedTheme: () => ({
    globalBackground: '#ecf6fe',
    palette: {
      primary: {
        main: '#246EA9',
      },
      primaryBtnHover: {
        main: '#cc774e',
      },
      buttonText: {
        main: '#1c1c1c',
      },
      secondary: {
        main: '#444054',
      },
      interaction: {
        main: '#B84C16',
      },
      text: {
        primary: '#1c1c1c',
      },
      link: {
        primary: '#1c1c1c',
      },
      completion: {
        primary: '#57a800',
      },
    },

    typography: {
      useNextVariants: true,
      fontFamily: [
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif',
        'Comic Sans MS',
        '"VT323", monospace',
      ].join(','),
    },

    overrides: {
      MuiButton: {
        contained: {
          color: 'black',
          backgroundColor: 'rgb(249, 157, 27);',
          '&:hover': {
            backgroundColor: 'rgb(254, 184, 84);',
          },
        },
      },
    },
  }),
  typography: (theme, remCalc) => ({
    h1: {
      color: '#1c1c1c',
      [theme.breakpoints.up('sm')]: {
        fontSize: remCalc(54),
        lineHeight: 1.11,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: remCalc(34),
        lineHeight: 1.24,
      },
    },
    h2: {
      color: '#1c1c1c',
      [theme.breakpoints.up('sm')]: {
        fontSize: remCalc(40),
        lineHeight: 1.15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: remCalc(30),
        lineHeight: 1.2,
      },
    },

  }),
  extraTypography: (theme, remCalc) => ({
    hero: {
      fontWeight: 'bold',
      color: '#1c1c1c',
      [theme.breakpoints.up('sm')]: {
        fontSize: remCalc(44),
        lineHeight: 1.09,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: remCalc(44),
        lineHeight: 1.16,
      },
    },
    card: {
      fontWeight: 'bold',
      color: '#1c1c1c',
      [theme.breakpoints.up('sm')]: {
        fontSize: remCalc(28),
        lineHeight: 1.16,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: remCalc(28),
        lineHeight: 1.21,
      },
    },
  }),
  extraOverrides: (theme) => {

  },
};
