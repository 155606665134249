import { decorate, observable, action } from 'mobx';
import _ from 'lodash';

import momentActivityStore from './MomentActivityStore';
import Api from '../api';
import config from '../../common/config';

const isProd = config.application === 'bright-prod';

class InboxStore {
  constructor() {
    this.totalNotificationsInboxCount = 0;
    this.hasNewCertificationFeedback = false;

    this.filterOptions = {
      momentOptions: [],
      userOptions: [],
      teamOptions: [],
    };

    this.firstRenderedInboxItemId = null;
    this.lastRenderedInboxItemId = null;
    this.hasNoMoreRecordsToShow = false;

    this.totalInboxCount = 0;
    this.inboxItems = [];
    this.totalItems = 0;
    this.isInboxFilterOptionsLoading = false;
    this.isInboxItemsLoading = false;
    this.showRefreshCue = false;

    this.selectedInboxItem = null;
    this.selectedUserProgress = null;
    this.selectedMomentActivity = null;
    this.selectedUserProgressRatings = [];
    this.isUserProgressLoading = false;

    this.INBOX_FETCH_BATCH_SIZE = 100;
  }

  async getInboxNotificationData() {
    const { data } = await Api.getInboxNotificationData();

    if (data) {
      this.totalNotificationsInboxCount = data.totalNotificationsInboxCount;
      this.hasNewCertificationFeedback = data.hasNewCertificationFeedback;
    }
  }

  async getInboxFilterOptions() {
    this.isInboxFilterOptionsLoading = true;
    const { data } = await Api.getInboxFilterOptions();
    this.isInboxFilterOptionsLoading = false;

    if (data) {
      this.filterOptions = data;
    }
  }

  prepareFilterQueryParams(filters) {
    const {
      selectedMoments,
      selectedUsers,
      selectedTeams,
      selectedStartDate,
      selectedEndDate,
      fetchOnlyUnratedMoments,
      firstRenderedInboxItemId,
      lastRenderedInboxItemId,
      pageSize,
    } = filters;
    const queryParams = [];

    if (selectedStartDate) {
      queryParams.push(`selectedStartDate=${selectedStartDate.toDate().toISOString()}`);
    }

    if (selectedEndDate) {
      queryParams.push(`selectedEndDate=${selectedEndDate.toDate().toISOString()}`);
    }

    if (fetchOnlyUnratedMoments) {
      queryParams.push(`fetchOnlyUnratedMoments=${fetchOnlyUnratedMoments}`);
    }

    if (selectedMoments && selectedMoments.length) {
      queryParams.push(`selectedMoments=${selectedMoments.join(',')}`);
    }

    if (selectedUsers && selectedUsers.length) {
      queryParams.push(`selectedUsers=${selectedUsers.join(',')}`);
    }

    if (selectedTeams && selectedTeams.length) {
      queryParams.push(`selectedTeams=${selectedTeams}`);
    }

    if (typeof lastRenderedInboxItemId === 'number') {
      queryParams.push(`lastRenderedInboxItemId=${lastRenderedInboxItemId}`);
    }

    if (typeof firstRenderedInboxItemId === 'number') {
      queryParams.push(`firstRenderedInboxItemId=${firstRenderedInboxItemId}`);
    }

    if (typeof pageSize === 'number') {
      queryParams.push(`pageSize=${pageSize}`);
    }

    return queryParams;
  }

  resetInboxListingData(shouldSetListAsLoading) {
    this.inboxItemsDetails = {};
    this.inboxItems = [];
    this.firstRenderedInboxItemId = null;
    this.lastRenderedInboxItemId = null;
    this.hasNoMoreRecordsToShow = false;

    if (shouldSetListAsLoading) {
      this.isInboxItemsLoading = true;
    }
  }

  async getInboxListing({ filters }) {
    this.isInboxItemsLoading = true;

    const { data: { inboxItems, totalItems } } = await Api.getInboxListing({
      ...filters,
      lastRenderedInboxItemId: this.lastRenderedInboxItemId,
      pageSize: this.INBOX_FETCH_BATCH_SIZE,
    });

    this.totalItems = totalItems;

    this.isInboxItemsLoading = false;

    if (inboxItems) {
      this.inboxItems = [...this.inboxItems, ...inboxItems];

      if (inboxItems.length >= 1) {
        if (this.firstRenderedInboxItemId === null) {
          // First fetch happening
          this.firstRenderedInboxItemId = inboxItems[0].id;
        }

        this.lastRenderedInboxItemId = inboxItems[inboxItems.length - 1].id;
      } else {
        this.hasNoMoreRecordsToShow = true;
      }
    }
  }

  async checkForShouldRefreshCue({ filters }) {
    const { data } = await Api.getInboxRefreshCheck({
      ...filters,
      firstRenderedInboxItemId: this.firstRenderedInboxItemId,
    });

    if (data && data.totalNewItems >= 1) {
      this.showRefreshCue = true;

      return;
    }

    this.showRefreshCue = false;
  }

  async hideRefreshCue() {
    this.showRefreshCue = false;
  }

  async markAllRead(type, scope = 'team') {
    await Api.markAllRead(type, scope);
  }

  async readInboxItem(item) {
    item.status = 'read';
    await Api.updateInboxItem(item);
  }

  async getUserProgress(userProgressId) {
    this.isUserProgressLoading = true;
    const { data } = await Api.getUserProgress(userProgressId);
    this.isUserProgressLoading = false;

    if (isProd && data.extra.file) {
      data.extra.file = data.extra.file.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    } else if (!isProd && data.extra.file) {
      data.extra.file = data.extra.file.replace('admin-dev', 'app-dev').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    }

    this.selectedUserProgress = data;
    this.selectedUserProgressRatings = [data];
    momentActivityStore.getMomentActivity(this.selectedUserProgress.momentId, this.selectedUserProgress.userId);

    if (data.type === 'video') {
      const mediaResponse = await Api.getCompositionMedia(data.momentId, data.extra.sid);
      this.sidUrl = mediaResponse.data.redirect_to;
    }
  }

  async getUserProgressTransaction(txId) {
    this.isUserProgressLoading = true;
    const { data } = await Api.getUserProgressByTransaction(txId);
    this.isUserProgressLoading = false;

    const first = data[0];

    if (!first) {
      this.selectedUserProgress = null;
      return;
    }

    if (isProd && first.extra.file) {
      first.extra.file = first.extra.file.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    } else if (!isProd && first.extra.file) {
      first.extra.file = first.extra.file.replace('admin-dev', 'app-dev').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    }

    this.selectedUserProgress = first;
    this.selectedUserProgressRatings = data;
    momentActivityStore.getMomentActivity(this.selectedUserProgress.momentId, this.selectedUserProgress.userId);

    if (first.type === 'video') {
      const mediaResponse = await Api.getCompositionMedia(first.momentId, first.extra.sid);
      this.sidUrl = mediaResponse.first.redirect_to;
    }
  }

  async changeSelectedProgressRating(rating, userProgress) {
    const found = this.selectedUserProgressRatings.find((s) => s.id === userProgress.id);

    const ratingThreshold = _.get(found, 'momentModel.extra.ratingThreshold');
    if (ratingThreshold) {
      if (rating < parseInt(ratingThreshold, 10)) {
        found.status = 'failed';
      } else {
        found.status = 'pass';
      }
    }

    found.extra = { ...found.extra, managerRating: rating };
  }

  async saveRatingAndComment({ comment, overriddenStarRatings }) {
    let isOverridden = false;

    if (this.selectedUserProgressRatings.find((up) => up.aiResult)) {
      this.selectedUserProgressRatings.forEach((up) => {
        if (up.aiResult.rating || up.aiResult.rating === 0) {
          const overriddenRating = overriddenStarRatings[up.id];
          if (overriddenRating && overriddenRating !== up.aiResult.rating) {
            isOverridden = true;
          }
        }
      });

      const {
        commentText,
        commentMediaUrl,
        commentMediaType,
        commentMediaDuration,
        commentFileUrl,
        commentFileName,
      } = comment;

      if (commentText ||
        commentMediaUrl ||
        commentMediaType ||
        commentMediaDuration ||
        commentFileUrl ||
        commentFileName) {
        isOverridden = true;
      }

      this.selectedUserProgressRatings = this.selectedUserProgressRatings.map((up) => {
        let managerRating = up.aiResult.rating;
        if (isOverridden && overriddenStarRatings[up.id]) {
          managerRating = overriddenStarRatings[up.id];
        }
        const ratingThreshold = _.get(up, 'momentModel.extra.ratingThreshold');
        if (ratingThreshold) {
          if (managerRating < parseInt(ratingThreshold, 10)) {
            up.status = 'failed';
          } else {
            up.status = 'pass';
          }
        }

        up.extra = { ...up.extra, managerRating };

        if (up.aiResult) {
          return {
            ...up,
            aiResult: {
              ...up.aiResult,
              isOverridden,
            },
          };
        }
        return {
          ...up,
          aiResult: {
            isOverridden,
          },
        };
      });
    }
    await momentActivityStore.postComment(this.selectedUserProgressRatings, this.selectedUserProgress.momentModel.id, comment, false);
    await Api.updateUserProgress(this.selectedUserProgressRatings);

    const indexToRemove = this.inboxItems.findIndex(({ id }) => id === this.selectedInboxItem.id);
    this.inboxItems.splice(indexToRemove, 1); // Remove it from the list

    delete this.inboxItemsDetails[this.selectedInboxItem.id]; // Remove details
  }

  async refreshAIRules() {
    try {
      const { data } = await Api.refreshAIRules(this.selectedUserProgress.momentId);

      if (data.code === 'InternalServerError') {
        throw new Error(data.data);
      }

      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  selectInboxItem(item) {
    this.selectedUserProgressRatings = [];
    this.selectedInboxItem = { ...item };
  }
}

decorate(InboxStore, {
  totalNotificationsInboxCount: observable,
  hasNewCertificationFeedback: observable,
  getInboxNotificationData: action,

  firstRenderedInboxItemId: observable,
  lastRenderedInboxItemId: observable,
  hasNoMoreRecordsToShow: observable,

  totalInboxCount: observable,
  showRefreshCue: observable,
  checkForShouldRefreshCue: action,

  filterOptions: observable,
  isInboxFilterOptionsLoading: observable,
  getInboxFilterOptions: action,

  inboxItems: observable,
  inboxItemsDetails: observable,
  isInboxItemsLoading: observable,
  resetInboxListingData: action,
  getInboxListing: action,

  selectedInboxItem: observable,
  selectedUserProgress: observable,
  selectedUserProgressRatings: observable,
  isUserProgressLoading: observable,
  sidUrl: observable,
  changeSelectedProgressRating: action,
  saveRatingAndComment: action,
  getUserProgress: action,
  getUserProgressTransaction: action,
  selectInboxItem: action,
  refreshSelectedUserProgress: action,
});

export default new InboxStore();
