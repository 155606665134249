import {
  decorate,
  observable,
  action,
  computed,
} from 'mobx';
import _ from 'lodash';

import Api from '../api';
import config from '../../common/config';

const isProd = config.application === 'bright-prod';

class MomentStore {
  constructor() {
    this.moments = [];
    this.momentsLoaded = false;
    this.selectedMoment = null;
  }

  getMoments = async () => {
    const multipleChoiceLoadedMoments = this.moments.filter(({ extra: { prompt } }) => prompt?.action === 'Select a choice');
    if (this.momentsLoaded && !multipleChoiceLoadedMoments.length) {
      return this.moments;
    }

    const { data } = await Api.getMoments();

    const multipleChoiceMoments = data.filter(({ extra: { prompt } }) => prompt?.action === 'Select a choice' && prompt?.questionOrder !== 'listed-order');
    const multipleChoiceMomentsIds = multipleChoiceMoments.map(({ id }) => id);

    const momentsWithFilteredQuestions = multipleChoiceMomentsIds.length ?
      await Api.getMomentWithFilteredQuestions(multipleChoiceMomentsIds) :
      { data: [] };

    const updatedMoments = multipleChoiceMomentsIds.length ?
      data.map((moment) => {
        if (multipleChoiceMomentsIds.includes(moment.id)) {
          const found = momentsWithFilteredQuestions.data
            .find((updatedMoment) => updatedMoment.id === moment.id);
          if (found) {
            return found;
          }
        }

        return moment;
      }) : data;

    this.moments = updatedMoments.map((d) => {
      if (d.extra.audioFile) {
        if (config.isProd) {
          d.extra.audioFile.url = d.extra.audioFile.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        } else {
          d.extra.audioFile.url = d.extra.audioFile.url.replace('admin-dev', 'app-dev').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        }

        d.extra.images = d.extra.images.map((image) => {
          image.url = image.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
          return image;
        });
      }

      return d;
    });
    this.momentsLoaded = true;
    return this.moments;
  }

  getMoment = async (momentId, forceFetch) => {
    if (this.momentsLoaded && !forceFetch) {
      const found = this.moments.find((m) => m.id === momentId);
      this.selectedMoment = found;

      return found;
    }

    const { data } = await Api.getMoment(momentId);

    if (data?.extra?.audioFile) {
      if (isProd) {
        data.extra.audioFile.url = data.extra.audioFile.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        data.extra.images = data.extra.images.map((image) => {
          image.url = image.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
          return image;
        });
      } else {
        data.extra.audioFile.url = data.extra.audioFile.url.replace('admin-dev', 'app-dev').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
      }
    }

    this.selectedMoment = data;
  }

  get homepageMoments() {
    return this.moments.filter((m) => {
      if (m.extra.status !== 'Active') {
        return false;
      }

      if (_.isBoolean(m.extra.showOnHomepage)) {
        return m.extra.showOnHomepage;
      }
      return !m.extra.hideHomepage;
    });
  }

  setSelectedMoment = (moment) => {
    this.selectedMoment = moment;
  }

  getLoadedMoment = (momentId) => {
    return this.moments.find((m) => m.id === momentId);
  }
}

decorate(MomentStore, {
  moments: observable,
  momentsLoaded: observable,
  selectedMoment: observable,
  getMoments: action,
  getMoment: action,
  getLoadedMoment: action,
  setSelectedMoment: action,
  homepageMoments: computed,
});

export default new MomentStore();
