import { decorate, observable, action } from 'mobx';

import Api from '../api';

class OnboardingStore {
  constructor() {
    this.welcomePlacemat = null;
    this.isWelcomePlacematLoading = true;
    this.landingPageConfigs = null;
    this.isFirstMount = true;
  }

  async fetchWelcomePlacemat() {
    this.isWelcomePlacematLoading = true;

    const { data } = await Api.getWelcomePlacemat();

    if (data) {
      this.welcomePlacemat = data;
    }

    this.isWelcomePlacematLoading = false;
  }

  async setLandingPageConfigs(configs) {
    this.landingPageConfigs = configs;
  }

  finishFirstMount() {
    this.isFirstMount = false;
  }
}

decorate(OnboardingStore, {
  welcomePlacemat: observable,
  isWelcomePlacematLoading: observable,
  landingPageConfigs: observable,
  fetchWelcomePlacemat: action,
  isFirstMount: observable,
  finishFirstMount: action,
});

export default new OnboardingStore();
