import {
  decorate, observable, action, computed,
} from 'mobx';
import _ from 'lodash';

import Api from '../api';
import LanguageStore from './LanguageStore';
import UserStore from './UserStore';
import NotificationStore from './NotificationStore';
import MomentStore from './MomentStore';
import inboxStore from './InboxStore';

const uuidv1 = require('uuid/v1');

class MomentActivityStore {
  constructor() {
    this.momentActivity = [];
    this.allMomentActivity = [];
    this.momentActivityLoaded = false;
    this.creatingComment = false;
    this.selectedActivity = null;
    this.endState = false;
    this.activeBreakpoint = null;
    this.lifelineMode = false;
    this.startTime = 0;

    this.showLifelineDrawer = false;
    this.systemMomentState = {
      status: 'in-progress',
      duration: 0,
      missedClicks: 0,
      lifelineCount: 0,
      events: [],
      feedback: [],
    };

    this.audioState = {
      player: null,
      currentTime: null,
      audioSrc: null,
      hitAudioBreakpoints: [],
      status: 'paused',
      isUserInteracted: false,
      pauseCount: 0,
      isFinished: false,
    };
  }

  setAudioSrc = (audioSrc) => {
    this.audioState.audioSrc = audioSrc;
  }

  setPlayer(player) {
    const self = this;
    if (player) {
      player.addEventListener('ended', () => {
        self.audioState.isFinished = true;
      });
      self.audioState.player = player;
    }
  }

  playAudio = () => {
    if (!this.audioState.isFinished && this.audioState.audioSrc) {
      this.audioState.status = 'playing';
      this.audioState.player.play();
    }
  }

  pauseAudio = (isUserInteracted = false) => {
    this.audioState.status = 'paused';
    if (isUserInteracted) {
      this.audioState.isUserInteracted = true;
    }
    this.audioState.player?.pause();
  }

  setCurrentTime(currentTime) {
    this.audioState.currentTime = currentTime;
  }

  seekTime(time) {
    this.audioState.player.currentTime = time;
  }

  resetAudioState() {
    this.audioState = {
      currentTime: null,
      audioSrc: null,
      hitAudioBreakpoints: [],
      status: 'paused',
      pauseCount: 0,
    };
  }

  endSystemMoment = async () => {
    if (this.systemMomentState && this.systemMomentState.duration === 0) {
      NotificationStore.createNotification('Something went wrong, duration cannot be zero!');
      return;
    }

    const missedClickThreshold = parseInt(_.get(MomentStore.selectedMoment, 'extra.missedClickThreshold', '10000000'), 10);
    const durationSecondsRaw = _.get(MomentStore.selectedMoment, 'extra.passDuration', '9999:99').split(':');
    const durMinsInSecs = parseInt(durationSecondsRaw[0], 10) * 60;
    const durSecs = parseInt(durationSecondsRaw[1], 10);

    this.systemMomentState.status = 'pass';
    if (this.systemMomentState.duration > (durMinsInSecs + durSecs)) {
      this.systemMomentState.status = 'failed';
    }
    if (this.systemMomentState.missedClicks > missedClickThreshold) {
      this.systemMomentState.status = 'failed';
    }

    await Api.createMomentActivity({
      name: 'Moment Activity',
      status: this.systemMomentState.status,
      type: MomentStore.selectedMoment.extra.type,
      momentId: MomentStore.selectedMoment.id,
      extra: this.systemMomentState,
    });

    await inboxStore.getInboxNotificationData();

    clearInterval(this.updateBySecond);
  }

  setEndState(endState) {
    this.endState = endState;
  }

  startTimer() {
    this.startTime = Date.now() - this.systemMomentState.duration * 1000;

    this.updateBySecond = setInterval(() => {
      const now = Date.now();

      this.systemMomentState.duration = Math.floor((now - this.startTime) / 1000);
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.updateBySecond);
    this.systemMomentState.duration = 0;
    this.startTime = 0;
  }

  markMissedClick() {
    if (this.systemMomentState.duration === 0) {
      return;
    }

    this.systemMomentState.missedClicks += 1;
  }

  spendLifeline = () => {
    this.systemMomentState.lifelineCount += 1;
    this.lifelineMode = true;
    this.showLifelineDrawer = true;
  }

  exitLifelineMode = () => {
    this.lifelineMode = false;
    this.showLifelineDrawer = false;
  }

  toggleLifelineDrawer = () => {
    this.showLifelineDrawer = !this.showLifelineDrawer;
  }

  get lifelinesLeft() {
    const allowedLifelines = parseInt(_.get(MomentStore.selectedMoment, 'extra.lifelineCount', 0), 10);
    return allowedLifelines - this.systemMomentState.lifelineCount;
  }

  get lifelineCount() {
    return this.systemMomentState.lifelineCount;
  }

  pushErrorFeedback(feedback, type = '') {
    this.systemMomentState.feedback.push({ type: `error-${type}`, feedback });
    NotificationStore.createNotification(feedback, 'error');
    setTimeout(() => {
      this.systemMomentState.feedback.shift();
    }, 5000);
  }

  pushSuccessFeedback(feedback) {
    this.systemMomentState.feedback.push({ type: 'success', feedback });
  }

  resetSystemMomentState() {
    this.systemMomentState = {
      status: 'in-progress',
      duration: 0,
      missedClicks: 0,
      lifelineCount: 0,
      events: [],
      feedback: [],
    };

    this.resetAudioState();
  }

  get activityTime() {
    const time = this.systemMomentState.duration;

    let minutes = Math.floor(time / 60);
    let seconds = (time - (60 * minutes)).toFixed();
    seconds = seconds < 10 ? `0${seconds}` : String(seconds);
    minutes = minutes < 10 ? `0${minutes}` : String(minutes);

    return `${minutes}:${seconds}`;
  }

  get latestFeedback() {
    if (this.systemMomentState.feedback.length > 0) {
      return this.systemMomentState.feedback[this.systemMomentState.feedback.length - 1];
    }

    return null;
  }

  get feedbackCount() {
    return (this.systemMomentState.feedback || []).length;
  }

  clearMomentState() {
    this.systemMomentState = {
      duration: 0,
      missedClicks: 0,
      events: [],
    };
  }

  getMomentActivity = async (momentId, userId) => {
    this.momentActivityLoaded = false;
    const { data } = await Api.getMomentActivity(momentId, userId);
    this.momentActivity = Object.keys(_.groupBy(data.filter((d) => d.transactionId != null), (d) => d.transactionId)).map((key) => data.find((d) => d.transactionId === key));
    this.allMomentActivity = data;

    this.momentActivityLoaded = true;
  }

  async createMomentActivity(moment, extra) {
    await Api.createMomentActivity({
      name: 'Moment Activity',
      status: moment.status,
      type: moment.extra.type,
      momentId: moment.id,
      extra,
    });

    NotificationStore.createNotification('Moment completed');

    if (moment.extra.prompt.action === 'Select a choice') {
      await MomentStore.getMoment(moment.id, true);
    }
  }

  async postComment(activities, momentId, comment, createNotification = true) {
    this.creatingComment = true;

    activities.forEach((activity) => {
      if (!activity.extra.comments) {
        activity.extra.comments = [];
      }
      activity.extra.comments.push({
        id: uuidv1(),
        date: new Date(),
        comment,
        commenter: {
          userId: UserStore.user.id,
          ..._.pick(UserStore.user, 'firstName', 'lastName', 'emailAddress'),
        },
      });
    });

    await Api.updateUserProgress(activities, createNotification);
    await this.getMomentActivity(momentId);

    NotificationStore.createNotification(LanguageStore.lang.t('notifications.commentPosted'));
    this.creatingComment = false;
  }

  async archiveMomentActivity(momentActivities) {
    momentActivities.forEach((activity) => {
      activity.extra.isArchived = true;
    });

    await Api.updateUserProgress(momentActivities, false);

    await inboxStore.getInboxNotificationData();
  }

  setSelectedActivity(selectedActivity) {
    this.selectedActivity = selectedActivity;
  }

  get lastMomentActivity() {
    if (this.selectedActivity != null) {
      return this.selectedActivity;
    }

    return this.momentActivity.length === 0 ? null : _.orderBy(this.momentActivity, 'version', ['desc'])[0];
  }

  get duration() {
    return this.systemMomentState.duration;
  }

  get missedClicks() {
    return this.systemMomentState.missedClicks;
  }
}

decorate(MomentActivityStore, {
  momentActivity: observable,
  momentActivityLoaded: observable,
  createMomentActivity: action,
  endSystemMoment: action,
  creatingComment: observable,
  selectedActivity: observable,
  getMoments: action,
  postComment: action,
  archiveMomentActivity: action,
  lastMomentActivity: computed,
  activityTime: computed,
  systemMomentState: observable,
  latestFeedback: computed,
  feedbackCount: computed,
  activeBreakpoint: observable,
  lifelineMode: observable,
  showLifelineDrawer: observable,
  allMomentActivity: observable,
  audioState: observable,
  lifelineCount: computed,
  duration: computed,
  missedClicks: computed,
  markMissedClick: action,
  startTime: observable,
  updateBySecond: observable,
});

export default new MomentActivityStore();
