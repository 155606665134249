import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';

import { remCalc } from '../utilities/style-util';
import { globalColors } from '../utilities/style-constants';

import rootTheme from './themes/rootTheme';

const baseTheme = {
  globalColors: {
    blackOnlyForButtons: globalColors.blackOnlyForButtons,
    white: globalColors.white,
    digitalBlack: globalColors.digitalBlack,
    mediumBlackIconOnly: global.mediumBlackIconOnly,
    mediumGrey: globalColors.mediumGrey,
    lightestGrey: globalColors.lightestGrey,
    greyDisabled: globalColors.greyDisabled,
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: globalColors.white,
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'inherit !important',
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontSize: remCalc(14),
        fontWeight: 600,
        color: globalColors.black,
        '&$selected': {
          color: globalColors.black,
        },
      },
    },
    MuiButton: {
      contained: {
        '&$disabled': {
          backgroundColor: '#d2d2d2',
          color: '#FFF',
        },
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: globalColors.white,
      },
    },
  },
};

class ThemeSelector extends Component {
  render() {
    const basicTheme = rootTheme.basic;

    const theme = createMuiTheme({ ...baseTheme, ...basicTheme.extendedTheme() });
    theme.overrides = { ...theme.overrides, ...basicTheme.extraOverrides };
    theme.typography = { ...theme.typography, ...basicTheme.typography(theme, remCalc) };
    theme.extraTypography = { ...basicTheme.extraTypography(theme, remCalc) };

    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default observer(ThemeSelector);
