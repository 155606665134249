import { decorate, observable, action } from 'mobx';
import _ from 'lodash';
import Api from '../api';
import UserStore from './UserStore';
import LanguageStore from './LanguageStore';
import NotificationStore from './NotificationStore';

import inboxStore from './InboxStore';

const uuidv1 = require('uuid/v1');

class CertificationActivityStore {
  constructor() {
    this.certificationActivity = [];
    this.certificationActivityLoaded = false;
    this.creatingComment = false;
    this.selectedActivity = null;
    this.allCertificationActivity = [];
  }

  async getCertificationActivity(userId, certificationId) {
    this.simulationActivityLoaded = false;
    const { data } = await Api.getCertificationActivity(userId, certificationId);
    this.certificationActivity = Object.keys(_.groupBy(data.filter((d) => d.transactionId != null), (d) => d.transactionId)).map((key) => data.find((d) => d.transactionId === key));
    this.certificationActivity = _.orderBy(this.certificationActivity, (d) => new Date(d.date_created).getTime() * -1);
    this.certificationActivityLoaded = true;
    this.allCertificationActivity = data;
  }

  // async getSimulationActivityById(simulationId, activityId) {
  //   this.simulationActivityLoaded = false;
  //   const { data } = await Api.getSimulationActivityByActivityId(simulationId, activityId);
  //   this.selectedActivity = data;
  // }

  // async completeSimulationStep(simulationId, activityId, stepId) {
  //   const { data } = await Api.completeSimulationStep(simulationId, activityId, stepId);
  //   this.selectedActivity = data;
  // }

  async createActivity(userId, certificationId, duration) {
    const { data } = await Api.createMomentActivity({
      name: 'Certification Activity',
      userId,
      certificationId,
      extra: {
        status: 'started',
        reviewerId: UserStore.user.id,
      },
    });

    this.selectedActivity = data;

    await inboxStore.getInboxNotificationData();

    return data.id;
  }

  async updateActivity(activity, updates) {
    const { data } = await Api.updateUserProgress(activity);
    await this.getCertificationActivity(activity.userId, activity.certificationId);

    await inboxStore.getInboxNotificationData();

    return data;
  }

  async postComment(activities, comment) {
    this.creatingComment = true;

    activities.forEach((activity) => {
      if (!activity.extra.comments) {
        activity.extra.comments = [];
      }
      activity.extra.comments.push({
        id: uuidv1(),
        date: new Date(),
        comment,
        commenter: {
          userId: UserStore.user.id,
          ..._.pick(UserStore.user, 'firstName', 'lastName', 'emailAddress'),
        },
      });
    });

    await Api.updateUserProgress(activities);

    await inboxStore.getInboxNotificationData();

    await this.getCertificationActivity(activities[0].userId, activities[0].certificationId);

    NotificationStore.createNotification(LanguageStore.lang.t('notifications.commentPosted'));
    this.creatingComment = false;
  }

  // get completedSteps() {
  //   return this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  // }

  // get nextSimulationStep() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.observe));
  //   const incompleteObserve = allObserve.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incompleteObserve.length > 0) {
  //     return { stage: 'observe', ...incompleteObserve[0] };
  //   }

  //   const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.engage));
  //   const incompleteEngage = allEngage.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incompleteEngage.length > 0) {
  //     return { stage: 'engage', ...incompleteObserve[0] };
  //   }

  //   const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.practice));
  //   const incompletePractice = allPractice.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incompletePractice.length > 0) {
  //     return { stage: 'practice', ...incompleteObserve[0] };
  //   }

  //   return null;
  // }

  // get observeExpanded() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.observe));
  //   const incomplete = allObserve.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incomplete.length > 0;
  // }

  // get engageExpanded() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.observe));
  //   const incomplete = allObserve.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incomplete.length > 0) {
  //     return false;
  //   }

  //   const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.engage));
  //   const incompleteEngage = allEngage.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incompleteEngage.length > 0;
  // }

  // get practiceExpanded() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.observe));
  //   const incomplete = allObserve.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incomplete.length > 0) {
  //     return false;
  //   }

  //   const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.engage));
  //   const incompleteEngage = allEngage.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   if (incompleteEngage.length > 0) {
  //     return false;
  //   }

  //   const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.practice));
  //   const incompletePractice = allPractice.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incompletePractice.length > 0;
  // }

  // get completedObserve() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.observe));
  //   const incomplete = allObserve.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incomplete.length === 0;
  // }

  // get completedEngage() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.engage));
  //   const incomplete = allEngage.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incomplete.length === 0;
  // }

  // get completedPractice() {
  //   const { selectedSimulation } = SimulationStore;
  //   const completedStepIds = this.selectedActivity.activity.filter(a => a.complete).map(a => a.stepId);
  //   const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map(m => m.stages.practice));
  //   const incomplete = allPractice.filter(o => completedStepIds.indexOf(o.stepId) === -1);

  //   return incomplete.length === 0;
  // }
}

decorate(CertificationActivityStore, {
  certificationActivity: observable,
  selectedActivity: observable,
  createActivity: action,
  allCertificationActivity: observable,
});

export default new CertificationActivityStore();
