import { decorate, observable, action } from 'mobx';
import _ from 'lodash';
import Api from '../api';

class CertificationStore {
  constructor() {
    this.certifications = [];
    this.certificationHistory = [];
    this.certificationActivity = [];
    this.certificationsLoaded = false;
    this.selectedCertification = null;
    this.creatingComment = false;
  }

  async getCertifications() {
    const { data } = await Api.getCertifications();
    this.certifications = data;
    this.certificationsLoaded = true;
  }

  async getCertification(certificationId) {
    const { data } = await Api.getCertification(certificationId);
    this.selectedCertification = data;
  }

  async getCertificationActivity(userId) {
    const { data } = await Api.getCertificationActivity(userId);
    this.certificationActivity = _.orderBy(data, (d) => new Date(d.date_created).getTime() * -1);
  }
}

decorate(CertificationStore, {
  certifications: observable,
  certificationsLoaded: observable,
  selectedCertification: observable,
  certificationActivity: observable,
  getCertifications: action,
  getCertification: action,
});

export default new CertificationStore();
