// @flow
import React, { Component } from 'react';
import StoreWrapper from '../../../StoreWrapper';
import SkeletonLoader from '@common/components/SkeletonLoader';

export default function asyncComponent(loadComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
        isLoading: false,
      };
    }

    componentDidMount() {
      this.fetchComponent();
    }

    async fetchComponent() {
      this.setState({ isLoading: true });
      const { default: component } = await loadComponent();

      this.setState({ component, isLoading: false });
    }

    render() {
      const C = this.state.component;

      if (this.state.isLoading) return <SkeletonLoader />;

      return C ? <StoreWrapper><C {...this.props} /></StoreWrapper> : null;
    }
  }

  return AsyncComponent;
}
