import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import useStyles from './styles';

const SkeletonLoader = ({
  isOnlyContent = false,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.headerContent}
        avatar={(
          <Skeleton
            animation="wave"
            variant="circle"
            width="3.5rem"
            height="3.5rem"
            classes={{ root: classes.skeleton }}
          />
        )}
        title={(
          <Skeleton
            animation="wave"
            height={15}
            width="90%"
            classes={{ root: cn(classes.skeleton, classes.margin) }}
          />
        )}
        subheader={(
          <Skeleton
            animation="wave"
            height={15}
            width="60%"
            classes={{ root: classes.skeleton }}
          />
        )}
        style={{ visibility: isOnlyContent ? 'hidden' : 'visible' }}
      />

      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.media}
        classes={{ root: classes.skeleton, wave: classes.bodyWave }}
      />

      <CardContent className={classes.headerContent}>
        <Skeleton
          animation="wave"
          height={15}
          classes={{ root: cn(classes.skeleton, classes.margin) }}
        />
        <Skeleton
          animation="wave"
          height={15}
          width="80%"
          classes={{ root: classes.skeleton }}
        />
      </CardContent>
    </Card>
  );
};

SkeletonLoader.propTypes = {
  isOnlyContent: PropTypes.bool,
};

export default SkeletonLoader;
