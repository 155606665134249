import { decorate, observable, action } from 'mobx';
import Api from '../api';

class TeamStore {
  constructor() {
    this.tierOneTeams = [];
    this.teams = [];
    this.isTeamDataLoading = false;
  }

  async getAllTeams() {
    this.isTeamDataLoading = true;

    const { data } = await Api.getAllTeams();
    this.teams = data;

    this.isTeamDataLoading = false;

    return data;
  }

  async getTierOneTeams() {
    this.isTeamDataLoading = true;

    const { data } = await Api.getTierOneTeams();

    if (data) {
      this.tierOneTeams = data.teams;
    }

    this.isTeamDataLoading = false;

    return data;
  }
}

decorate(TeamStore, {
  teams: observable,
  isTeamDataLoading: observable,
  tierOneTeams: observable,
  getAllTeams: action,
});

export default new TeamStore();
