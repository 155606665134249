const includes = require('lodash/includes');

const supportedLanugaeCodes = ['en'];
const defaultLanguageCode = 'en';

function getDefaultLanguagePack() {
  return require(`./phrases_${defaultLanguageCode}.js`).default;
}

function getLanguagePack(languageCode) {
  const validLanguageCode = languageCode && includes(supportedLanugaeCodes, languageCode);
  const languagePhrases = validLanguageCode
    ? require(`./phrases_${languageCode}`).default
    : getDefaultLanguagePack();

  return languagePhrases;
}

module.exports = {
  defaultLanguageCode,
  getLanguagePack,
  getDefaultLanguagePack,
};
