// @flow
import {
  decorate, observable, action, computed,
} from 'mobx';
import Api from '../api';

class ResourceStore {
  resources = [];

  selectedResource;

  creatingResource;

  resourceDataLoaded = false;

  async getResources(force = false) {
    if (this.resources.length > 0 && !force) {
      return this.resources;
    }

    const { data } = await Api.getResources();
    this.resources = data.filter((d) => d.url != null).map((d) => {
      d.url = d.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');

      return d;
    });

    this.resourceDataLoaded = true;
  }

  setSelectedResource(resource) {
    this.selectedResource = resource;
  }

  async getResource(resourceId) {
    const { data } = await Api.getResource(resourceId);
    this.selectedResource = data;
  }

  async createResource(course) {
    this.creatingResource = true;
    try {
      const created = await Api.createResource(course);
      this.resources = [];
      await this.getResources();
      this.creatingResource = false;

      return created.data;
    } catch (e) {
      this.creatingResource = false;
    }
  }

  async deleteResource(resourceId) {
    this.creatingResource = true;
    try {
      const created = await Api.deleteResource(resourceId);
      this.resources = [];
      await this.getResources();
      this.creatingResource = false;

      return created.data;
    } catch (e) {
      this.creatingResource = false;
    }
  }

  async updateResource(course) {
    delete course.date_created;
    delete course.date_updated;

    this.creatingCourse = true;
    try {
      const created = await Api.updateResource(course);
      this.courses = [];
      this.creatingCourse = false;

      this.resources = this.resources.map((r) => {
        if (r.id !== created.data.id) {
          return r;
        }
        return created.data;
      });

      return created.data;
    } catch (e) {
      this.creatingCourse = false;
    }
  }

  get homepageResources() {
    return this.resources.filter((r) => r.extra.showHomepage);
  }

  async uploadCourseFile(resourceId, uploadData) {
    await Api.uploadCourseFile(resourceId, uploadData);
  }
}

decorate(ResourceStore, {
  resources: observable,
  resourceDataLoaded: observable,
  getResource: action,
  getResources: action,
  getLessons: action,
  createResource: action,
  updateResource: action,
  setSelectedResource: action,
  homepageResources: computed,
});

export default new ResourceStore();
