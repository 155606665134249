import React from 'react';
import ReactDOM from 'react-dom';

import FrontendRoot from './frontend/containers/Root';
import * as serviceWorker from './frontend/service-worker';

import './common/styles/fonts.css';
import './common/styles/global.css';
import './common/styles/video-js.css';
import './common/styles/video-js-record.css';

import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/es/number';

const render = (Component) => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root'),
  );
};

render(FrontendRoot);

const { hot } = module;

if (hot) {
  hot.accept('./frontend/containers/Root', () => {
    render(require('./frontend/containers/Root').default);
  });
}

serviceWorker.unregister();
