import { decorate, observable, action } from 'mobx';

class NotificationStore {
  constructor() {
    this.notifications = [];
  }

  createNotification = (label, severity = 'info') => {
    const self = this;

    this.notifications.push(
      {
        type: 'string',
        label,
        severity,
      },
    );

    setTimeout(() => {
      self.notifications = this.notifications
        .filter((notification) => label !== notification.label);
    }, 5000);
  }
}

decorate(NotificationStore, {
  notifications: observable,
  clearNotification: action,
});

export default new NotificationStore();
