import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  card: {
    height: '100%',
    boxShadow: 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
  },
  media: {
    height: 'calc(100% - 15.25rem)',
    margin: '2rem',
    borderRadius: '8px',
  },
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  margin: {
    marginBottom: '8px',
  },
  bodyWave: {
    '&::after': {
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent)',
    },
  },
  headerContent: {
    padding: '20px 2rem 12px',
  },
  footerContent: {
    height: '5.625rem',
    padding: '25px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
