import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faVideo,
  faTags,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFileAlt,
  faChevronRight,
  faChevronLeft,
  faLifeRing,
  faPaperPlane,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

import {
  faPlus,
  faMinus,
  faTimes,
  faBars,
  faPrint,
  faEnvelope,
  faCloudUpload,
  faArrowToBottom,
  faChevronDown,
  faChevronUp,
  faBullseyeArrow,
  faMapSigns,
  faFilter,
  faShare,
  faArrowDown,
  faArrowUp,
  faQuestionCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faClock,
  faCheckCircle,
  faCheck,
  faArrowRight,
  faArrowLeft,
  faPlay,
  faFile,
  faLink,
  faPause,
  faUndo,
  faInfoCircle,
  faCircle,
  faComment,
  faTachometerAltFastest,
} from '@fortawesome/pro-light-svg-icons';

import {
  faUserCircle,
  faBell,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faBullseyeArrow,
  faBook,
  faCheck,
  faVideo,
  faTags,
  faArrowRight,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFile,
  faLink,
  faFileAlt,
  faPlay,
  faArrowToBottom,
  faPrint,
  faUserCircle,
  faBell,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faBullseyeArrow,
  faMapSigns,
  faBars,
  faTimes,
  faPlus,
  faMinus,
  faEnvelope,
  faClock,
  faFilter,
  faShare,
  faArrowDown,
  faArrowUp,
  faLifeRing,
  faPause,
  faUndo,
  faQuestionCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faClock,
  faCheckCircle,
  faArrowLeft,
  faCircle,
  faInfoCircle,
  faCloudUpload,
  faPaperPlane,
  faComment,
  faTachometerAltFastest,
  faThumbtack,
);
