import { decorate, observable, action } from 'mobx';
import Api from '../api';

class CoachStore {
  constructor() {
    this.filterOptions = {};
    this.isFilterOptionsLoading = true;
  }

  async fetchCoachReportFilterOptions() {
    this.isFilterOptionsLoading = true;

    const { data } = await Api.getCoachReportFilterOptions();

    if (data) {
      this.filterOptions = data;
    }

    this.isFilterOptionsLoading = false;
  }

  resetCoachStore() {
    this.filterOptions = {};
    this.isFilterOptionsLoading = false;
  }
}

decorate(CoachStore, {
  filterOptions: observable,
  isFilterOptionsLoading: observable,
  fetchCoachReportFilterOptions: action,
});

export default new CoachStore();
