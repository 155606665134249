// @flow
import {
  action,
  decorate,
  computed,
  observable,
  toJS,
} from 'mobx';
import _ from 'lodash';
import Api from '../api';

import { adaptUrlDomainAccordingToEnvironment } from '../../common/utilities/helpers';
import config from '../../common/config';

const isProd = config.application === 'bright-prod';

class ScreenStore {
  screens: []

  momentScreens: []

  getScreens = async () => {
    const { data } = await Api.getScreens();
    this.screens = data.map((d) => {
      if (isProd) {
        if (d.canvas && d.canvas.src) {
          d.canvas.src = d.canvas.src.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        }
      }

      return d;
    });
  }

  getScreen = async (screenId, checkMomentScreenCache) => {
    this.currentScreen = null;
    const screenInCache = toJS((this.screensInCanvas || [])
      .find(({ id }) => id === screenId));
    if (checkMomentScreenCache && !!screenInCache) {
      screenInCache.canvas.src = `${adaptUrlDomainAccordingToEnvironment(screenInCache.canvas.src)}?id=${screenId}`;
      this.currentScreen = screenInCache;
      return this.currentScreen;
    }

    const { data } = await Api.getScreen(screenId);

    this.currentScreen = data;

    if (this.currentScreen.canvas.src) {
      this.currentScreen.canvas.src = `${adaptUrlDomainAccordingToEnvironment(data.canvas.src)}?id=${data.id}`;
    }

    return data;
  }

  setLayer(layer) {
    const currentScreen = { ...this.currentScreen };
    const layers = [...currentScreen.screenLayers];
    const newLayers = layers.map((l) => {
      const clickedLayer = l.extra.uuid === layer.extra.uuid;
      if (clickedLayer && l && l.extra && l.extra.meta && l.extra.meta.pulse && l.extra.meta.pulse.toggle) {
        l.extra.meta.pulse.toggle = false;
      }
      return l;
    });
    currentScreen.screenLayers = newLayers;
    this.currentScreen = currentScreen;
  }

  getScreenGroup = async (screenGroupId) => {
    if (this.currentScreenGroup?.id === screenGroupId) {
      this.screensInCanvas = this.currentScreenGroup.screens;
      return this.currentScreenGroup;
    }
    const { data } = await Api.getScreenGroup(screenGroupId);
    this.currentScreenGroup = data;
    this.screensInCanvas = data?.screens;
    return data;
  }

  setMomentScreens(screens) {
    this.momentScreens = screens;
  }

  async cacheImages(id, isCertification) {
    const screens = isCertification ? await Api.getCertificationScreens(id) : await Api.getMomentScreens(id);
    screens.data = screens.data.map((d) => {
      if (isProd) {
        if (d.canvas && d.canvas.src) {
          d.canvas.src = d.canvas.src.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com').replace();
        }
      }

      return d;
    });

    this.setMomentScreens(screens.data);

    screens.data.forEach((screen) => new Image().src = _.get(screen, 'canvas.src'));
  }

  get getCurrentScreen() {
    return this.currentScreen;
  }

  get currentScreenHasHTMLLayers() {
    return this.currentScreen.screenLayers.find((l) => l.type === 'html') != null;
  }

  get jumpLocations() {
    return (this.screens || []).filter((s) => s.id !== (this.currentScreen || {}).id);
  }
}

decorate(ScreenStore, {
  getScreens: action,
  getScreen: action,
  getScreenGroup: action,
  setLayer: action,
  currentScreenGroup: observable,
  screensInCanvas: observable,
  screens: observable,
  currentScreen: observable,
  getCurrentScreen: computed,
  currentScreenHasHTMLLayers: computed,
  jumpLocations: computed,
});

export default new ScreenStore();
