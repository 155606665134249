// @flow
const config = {
  local: 'local',
  qa: 'qa',
  dev: 'dev',
  prod: 'prod',
  preprod: 'preprod',
  'academy-prod': 'academy-prod',
  'academy-local': 'academy-local',
  'academy-preprod': 'academy-preprod',
  'dev-v2': 'dev-v2',
};

function getConfig(env) {
  const appConfig = env ?
    require(`./${config[env]}`) :
    require(`./${config.local}.js`);

  appConfig.env = env;

  return appConfig;
}

export default {
  isLocal: process.env.REACT_APP_BUILD_ENV === 'local' || process.env.REACT_APP_BUILD_ENV === 'academy-local',
  isProd: process.env.REACT_APP_BUILD_ENV === 'prod' || process.env.REACT_APP_BUILD_ENV === 'academy-prod',
  isAcademy: process.env.REACT_APP_BUILD_ENV.includes('academy'),
  ...getConfig(process.env.REACT_APP_BUILD_ENV),
};
