// @flow
import React, { Children } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

const StoreWrapper = ({
  children,
  ...stores
}) => {
  const renderedChildren = Children.map(children,
    (child) => React.cloneElement(child, stores));

  return (
    <>
      {renderedChildren}
    </>
  );
};

StoreWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default inject(
  'userStore',
  'languageStore',
  'onboardingStore',
  'momentStore',
  'momentActivityStore',
  'simulationStore',
  'simulationActivityStore',
  'notificationsStore',
  'inboxStore',
  'certificationStore',
  'screenStore',
  'certificationActivityStore',
  'resourceStore',
  'teamStore',
  'reportStore',
  'assignmentStore',
  'coachStore',
  'AIConversationStore',
)(observer(StoreWrapper));
