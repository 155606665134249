export default {
  onboarding: {
    introText: 'Hi %{name}. Welcome to Bright.',
    welcome: 'Welcome',
    roleSelectionHighlight: 'Select a Role',
    regionSelectionHighlight: 'Select a Location',
    startDateSelectionHighlight: 'Select a Start Date',
    roleSelectionTitle: 'What is your role?',
    regionSelectionTitle: 'Where are you located?',
    startDateSelectionTitle: 'When did you start?',
  },
  header: {
    profile: 'Profile',
    help: 'Help',
    logout: 'Log Out',
  },
  dashboard: {
    title: 'Welcome back, %{name}.',
    momentsPracticed: 'Moments Practiced',
    certifications: 'Certifications Achieved',
    due: 'Items Due Soon',
  },
  momentDetail: {
    startMoment: 'Start moment',
    resumeMoment: 'Resume moment',
    revisitMoment: 'Revisit moment',
    seePrior: 'See Prior Versions',
    visitResource: 'Visit Resource',
  },
  simulationDetail: {
    startSimulation: 'Start Simulation',
    resumeSimulation: 'Resume Simulation',
    inProgress: 'In Progress',
    inProgressDescription: 'You\'ve submitted all activities for this page! Visit My Path to see coach feedback + ratings. This course will be marked complete once you\'ve passed all Moments and Simulations.',
    completed: 'Complete',
    completedDescription: 'Congrats! You passed all your moments or simulations!',
    observeTitle: 'Observe',
    engageTitle: 'Engage',
    practiceTitle: 'Practice',
    congratsModalTitle: 'Congrats!',
    congratsModalDescription: 'You\'ve completed the initial stage of this experience. If there are any Moments or Simulations that require coach review or iteration, you can visit \'My Path\' to see feedback and keep practicing. This page will be marked complete after you\'ve passed all Activities.',
    myPathButton: 'Go to My Path',
    nextSimulation: 'Next Course/Simulation',
    dismissButton: 'Dismiss',
  },
  simulationPreview: {
    title: "During this simulation, you'll practice these core skills...",
    stage: 'Stage',
    startButton: 'Start!',
    tryAgainButton: 'Try Again!',
    historyTitle: 'Practice History',
    review: 'Review',
    score: 'Conversation Score',
    goBack: 'Go Back',
  },
  comments: {
    createPlaceholder: 'Type your comment here',
    createButton: 'Comment',
    resetButton: 'Reset',
  },
  notifications: {
    commentPosted: 'Comment Posted',
  },
  simulationRunner: {
    exitSimulation: 'Exit Simulation',
  },
  systemMomentRunner: {
    completeMomentModalDescription: 'If you\'d like to get more practice with this type of exercise, you can click \'Try again\' to start the simulation over. Otherwise, click \'Continue\' below to proceed.',
  },
  logout: {
    label: 'You have been logged out.',
  },
  inbox: {
    submit: 'Save & Archive',
    saveInfo: 'Please add star rating before you Save & Archive!',
  },
};
