import { sortBy } from 'lodash';

export const getMomentLatestStatusAndActivity = (
  moment,
  uniqueProgress,
  selectedUserActivity,
) => {
  const momentProgress = {
    latestStatus: 'not_started',
    latestActivity: null,
  };

  const filteredUserActivities = selectedUserActivity.filter(
    (activity) => activity.momentId === moment.id,
  );

  // get all skills (user progress rows) from the same attempt:
  const attempts = filteredUserActivities.reduce((acc, activity) => {
    if (!acc[activity.transactionId]) {
      acc[activity.transactionId] = [activity];
    } else {
      acc[activity.transactionId].push(activity);
    }

    return acc;
  }, {});

  // get last passed attempt. If there is no passed attempt, get last attempt.
  const sortByDate = sortBy(
    Object.values(attempts),
    ([someSkill]) => Date.parse(someSkill.date_updated),
  )?.reverse();

  const lastPassedActivity = sortByDate.find((attempt) => attempt.every((skill) =>
    skill.extra.managerRating >= skill.momentModel.extra.ratingThreshold
      || ['pass', 'completed'].includes(skill.status)));

  const lastActivity = lastPassedActivity || sortByDate?.[0] || null;

  if (lastActivity) {
    const foundCompletion = !!lastPassedActivity;
    const foundInProgress = lastActivity.some(({ status }) => status === 'in_progress');
    const foundFailed = lastActivity.some(({ status }) => status === 'failed');
    const foundSubmitted = lastActivity.some(({ status }) => status === 'submitted');

    if (foundSubmitted || foundFailed || foundInProgress) {
      momentProgress.latestStatus = (foundFailed && !foundInProgress)
        ? 'retake'
        : 'in_progress';
      uniqueProgress.inProgress.add(moment.id);
    }

    if (foundCompletion) {
      momentProgress.latestStatus = 'completed';
      uniqueProgress.passed.add(moment.id);
    }

    momentProgress.latestActivity = { ...lastActivity[0], status: momentProgress.latestStatus };
  } else {
    uniqueProgress.notStarted.add(moment.id);
  }

  return momentProgress;
};

export const calculateSimulationProgress = (
  simulations,
  selectedUserConversationActivity,
  selectedUserActivity,
  uniqueProgress,
  assignmentItemsInRetakeStatus,
  assignmentItemsInCompletedStatus,
) => {
  const simulationsWithStatus = {};

  simulations?.forEach((simulation) => {
    let totalMoments = 0;
    let totalConversations = 0;

    let numberOfConversationsCompleted = 0;
    let numberOfConversationsInProgress = 0;
    let numberOfMomentsCompleted = 0;
    let numberOfMomentsInProgress = 0;

    simulation.ContentAreas.forEach((contentArea) => {
      const { isAI } = contentArea;

      if (isAI) {
        totalConversations++;
        const userAttempts = selectedUserConversationActivity
          .filter(({ contentAreaId }) => contentAreaId === contentArea.id);
        const isPassed = userAttempts?.some(({ status }) => status === 'PASSED');

        if (userAttempts?.length && !isPassed) {
          assignmentItemsInRetakeStatus.push({
            ...contentArea,
            status: 'retake',
            activity: userAttempts?.[0],
            sequenceType: 'conversation',
            isPartOfSimulation: true,
          });

          numberOfConversationsInProgress++;

          uniqueProgress.inProgress.add(`AI${contentArea.id}`);
        } else if (isPassed) {
          numberOfConversationsCompleted++;

          uniqueProgress.passed.add(`AI${contentArea.id}`);
        } else {
          uniqueProgress.notStarted.add(`AI${contentArea.id}`);
        }
      } else {
        contentArea.Moments.forEach((momentOfThisContentArea) => {
          if (momentOfThisContentArea) {
            totalMoments++;

            const {
              latestStatus,
              latestActivity,
            } = getMomentLatestStatusAndActivity(
              momentOfThisContentArea,
              uniqueProgress,
              selectedUserActivity,
            );

            momentOfThisContentArea.status = 'not_started';

            const toPush = {
              ...momentOfThisContentArea,
              status: latestStatus,
              activity: latestActivity,
              sequenceType: 'moment',
              isPartOfSimulation: true,
            };

            if (latestStatus === 'completed') {
              momentOfThisContentArea.status = 'completed';
              numberOfMomentsCompleted++;

              assignmentItemsInCompletedStatus.push(toPush);
            } else if (latestStatus === 'retake') {
              momentOfThisContentArea.status = 'retake';
              numberOfMomentsInProgress++;

              assignmentItemsInRetakeStatus.push(toPush);
            } else if (latestStatus === 'in_progress') {
              momentOfThisContentArea.status = 'in_progress';
              numberOfMomentsInProgress++;
            }
          }
        });
      }
    });

    const hasAtleaseOneCompletion
      = (numberOfMomentsCompleted + numberOfConversationsCompleted) > 0;
    const hasAtleastOneInProgress
      = (numberOfMomentsInProgress + numberOfConversationsInProgress) > 0;
    const hasAllCompletions = (numberOfMomentsCompleted === totalMoments)
      && (numberOfConversationsCompleted === totalConversations);

    if (hasAllCompletions) {
      simulation.status = 'completed';
    } else if (hasAtleaseOneCompletion || hasAtleastOneInProgress) {
      simulation.status = 'in_progress';
    } else {
      simulation.status = 'not_started';
    }

    simulation.totalMoments = totalMoments + totalConversations;
    simulation.numberOfMomentsCompleted
        = numberOfMomentsCompleted + numberOfConversationsCompleted;
    simulation.numberOfMomentsInProgress
        = numberOfMomentsInProgress + numberOfConversationsInProgress;

    simulationsWithStatus[simulation.id] = simulation;
  });

  return simulationsWithStatus;
};

export const calculateMomentProgress = (
  moments,
  uniqueProgress,
  selectedUserActivity,
) => {
  const momentsWithStatus = {};

  moments?.forEach((moment) => {
    const { latestStatus, latestActivity } = getMomentLatestStatusAndActivity(
      moment,
      uniqueProgress,
      selectedUserActivity,
    );

    moment.status = latestStatus;

    if (latestActivity) {
      moment.activity = latestActivity;
    }

    momentsWithStatus[moment.id] = moment;
  });

  return momentsWithStatus;
};

export const calculateAssignmentStatus = (
  userPathAssignment,
  momentsWithStatus,
  simulationsWithStatus,
  assignmentItemsInNotStartedStatus,
  assignmentItemsInRetakeStatus,
  assignmentItemsInProgressStatus,
  assignmentItemsInCompletedStatus,
  allSimulationsWithMomentsInfo,
  allAssignmentsInOrder,
) => {
  userPathAssignment?.forEach((sequence) => {
    let toPush = {};

    if (sequence.sequenceType === 'moment') {
      toPush = {
        ...momentsWithStatus[sequence.id],
        sequenceType: 'moment',
      };

      switch (momentsWithStatus[sequence.id].status) {
        case 'in_progress': {
          assignmentItemsInProgressStatus.push(toPush);
          break;
        }
        case 'retake': {
          assignmentItemsInRetakeStatus.push(toPush);
          break;
        }
        case 'completed': {
          assignmentItemsInCompletedStatus.push(toPush);
          break;
        }
        case 'not_started':
        default: {
          assignmentItemsInNotStartedStatus.push(toPush);
          break;
        }
      }
    } else if (sequence.sequenceType === 'simulation') {
      toPush = {
        ...simulationsWithStatus[sequence.id],
        sequenceType: 'simulation',
      };

      switch (simulationsWithStatus[sequence.id].status) {
        case 'in_progress': {
          assignmentItemsInProgressStatus.push(toPush);
          break;
        }
        case 'completed': {
          assignmentItemsInCompletedStatus.push(toPush);
          break;
        }
        case 'not_started':
        default: {
          assignmentItemsInNotStartedStatus.push(toPush);
          break;
        }
      }

      allSimulationsWithMomentsInfo.push(toPush);
    }

    if (['completed', 'in_progress', 'not_started', 'retake'].includes(sequence.status)) {
      allAssignmentsInOrder.push(toPush);
    }
  });
};
