import {
  decorate, observable, action, computed,
} from 'mobx';
import * as mobx from 'mobx';
import _ from 'lodash';
import Api from '../api';
import SimulationStore from './SimulationStore';
import inboxStore from './InboxStore';

class SimulationActivityStore {
  constructor() {
    this.simulationActivity = [];
    this.simulationActivityLoaded = false;
    this.creatingComment = false;
    this.selectedActivity = null;
  }

  async getSimulationActivity(simulationId) {
    this.simulationActivityLoaded = false;
    const { data } = await Api.getSimulationActivity(simulationId);
    this.simulationActivity = data;
    this.simulationActivityLoaded = true;
  }

  async getSimulationActivityById(simulationId, activityId) {
    this.simulationActivityLoaded = false;
    const { data } = await Api.getSimulationActivityByActivityId(simulationId, activityId);
    this.selectedActivity = data;
  }

  async completeSimulationStep(simulationId, activityId, stepId) {
    const { data } = await Api.completeSimulationStep(simulationId, activityId, stepId);
    this.selectedActivity = data;
  }

  async createActivity(simulationId) {
    const { data } = await Api.createSimulationActivity(simulationId);
    this.selectedActivity = data;

    await inboxStore.getInboxNotificationData();

    return data.id;
  }

  get completedSteps() {
    return this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
  }

  get nextSimulationStep() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.observe));
    const incompleteObserve = allObserve.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incompleteObserve.length > 0) {
      return { stage: 'observe', ...incompleteObserve[0] };
    }

    const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.engage));
    const incompleteEngage = allEngage.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incompleteEngage.length > 0) {
      return { stage: 'engage', ...incompleteObserve[0] };
    }

    const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.practice));
    const incompletePractice = allPractice.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incompletePractice.length > 0) {
      return { stage: 'practice', ...incompleteObserve[0] };
    }

    return null;
  }

  get observeExpanded() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.observe));
    const incomplete = allObserve.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incomplete.length > 0;
  }

  get engageExpanded() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.observe));
    const incomplete = allObserve.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incomplete.length > 0) {
      return false;
    }

    const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.engage));
    const incompleteEngage = allEngage.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incompleteEngage.length > 0;
  }

  get practiceExpanded() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.observe));
    const incomplete = allObserve.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incomplete.length > 0) {
      return false;
    }

    const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.engage));
    const incompleteEngage = allEngage.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    if (incompleteEngage.length > 0) {
      return false;
    }

    const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.practice));
    const incompletePractice = allPractice.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incompletePractice.length > 0;
  }

  get completedObserve() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allObserve = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.observe));
    const incomplete = allObserve.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incomplete.length === 0;
  }

  get completedEngage() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allEngage = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.engage));
    const incomplete = allEngage.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incomplete.length === 0;
  }

  get completedPractice() {
    const { selectedSimulation } = SimulationStore;
    const completedStepIds = this.selectedActivity.activity.filter((a) => a.complete).map((a) => a.stepId);
    const allPractice = _.flatten(mobx.toJS(selectedSimulation.moments).map((m) => m.stages.practice));
    const incomplete = allPractice.filter((o) => completedStepIds.indexOf(o.stepId) === -1);

    return incomplete.length === 0;
  }
}

decorate(SimulationActivityStore, {
  simulationActivity: observable,
  selectedActivity: observable,
  createActivity: action,
  completeActivityStep: action,
  nextSimulationStep: computed,
  completedSteps: computed,
  observeExpanded: computed,
  engageExpanded: computed,
  practiceExpanded: computed,
  completedObserve: computed,
  completedEngage: computed,
  completedPractice: computed,
});

export default new SimulationActivityStore();
