import { remCalc } from './style-util';

export const globalColors = {
  blackOnlyForButtons: '#000',
  white: '#FFF',
  digitalBlack: '#1c1c1c',
  mediumBlackIconOnly: '#494949',
  mediumGrey: '#707070',
  lightestGrey: '#f4f4f4',
  greyDisabled: '#d2d2d2',
  black: '#000',
  button: '#246EA9',
  buttonHover: '#2a86d1',
  backgroundBlack: '#fefefe',
};

// Global (non-theme) styles
export const globalStyles = {
  responsiveH1: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      fontSize: remCalc(80),
      lineHeight: remCalc(90),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: remCalc(56),
      lineHeight: remCalc(64),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: remCalc(32),
      lineHeight: remCalc(38),
    },
  }),
  widthWrapper: (theme) => (
    {
      [theme.breakpoints.down('xl')]: {
        maxWidth: remCalc(1440),
      },

      margin: '0 auto',
      [theme.breakpoints.between('md', 'lg')]: {
        padding: remCalc(0, 50),
      },
      [theme.breakpoints.down('sm')]: {
        padding: remCalc(0, 25),
      },
    }
  ),
  responsiveContainer: (theme) => ({
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: remCalc(1248),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: remCalc(600),
    },
    [theme.breakpoints.down('xs')]: {
      width: remCalc(343),
    },
  }),
};
