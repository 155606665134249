const remBase = 16;

export function convertPixelsToRem(px) {
  const pixelSize = parseInt(px, 10);

  if (Number.isNaN(pixelSize) || String(px).endsWith('%')) {
    return px;
  }

  return pixelSize === 0 ? '0' : `${pixelSize / remBase}rem`;
}

export function remCalc(...pxSizes) {
  if (pxSizes.length > 4) {
    throw new Error(`CSS shortcuts have a max of 4 sizes. Recieved: ${pxSizes.length}`);
  }

  return pxSizes.map(convertPixelsToRem).join(' ');
}
