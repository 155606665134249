import {
  decorate,
  observable,
  action,
  computed,
} from 'mobx';

import Api from '../api';
import config from '../../common/config';

const isProd = config.application === 'bright-prod';

class SimulationStore {
  constructor() {
    this.simulations = [];
    this.simulationsLoaded = false;
    this.selectedSimulation = null;
  }

  async getSimulations() {
    const { data } = await Api.getSimulations();

    this.simulations = data.map((simulation) => {
      if (isProd) {
        /* eslint-disable no-param-reassign */
        simulation.extra.images = simulation.extra.images.map((image) => {
          image.url = image.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
          /* eslint-enable no-param-reassign */
          return image;
        });
      }

      return simulation;
    });

    this.simulationsLoaded = true;
  }

  async getSimulation(simulationId) {
    const { data: simulation } = await Api.getSimulation(simulationId);

    if (isProd) {
      simulation.extra.images = simulation.extra.images.map((image) => {
        // eslint-disable-next-line no-param-reassign
        image.url = image.url.replace('admin-dev', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        return image;
      });
    }

    this.selectedSimulation = simulation;

    return simulation;
  }

  get getSelectedSimulation() {
    return this.selectedSimulation;
  }
}

decorate(SimulationStore, {
  simulations: observable,
  simulationsLoaded: observable,
  selectedSimulation: observable,
  getSelectedSimulation: computed,
  getSimulations: action,
  getSimulation: action,
});

export default new SimulationStore();
