import React from 'react';
import { CssBaseline } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  matchPath,
} from 'react-router-dom';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';

import asyncComponent from '../components/core/asyncComponent';
import '../components/icons/FontAwesome';

import ThemeSelector from '../../common/styles/ThemeSelector';
import ScrollToTop from '../../common/components/ScrollToTop';

import UserStore from '../stores/UserStore';
import LanguageStore from '../stores/LanguageStore';
import OnboardingStore from '../stores/OnboardingStore';
import MomentStore from '../stores/MomentStore';
import MomentActivityStore from '../stores/MomentActivityStore';
import NotificationStore from '../stores/NotificationStore';
import SimulationStore from '../stores/SimulationStore';
import SimulationActivityStore from '../stores/SimulationActivityStore';
import InboxStore from '../stores/InboxStore';
import CertificationStore from '../stores/CertificationStore';
import ScreenStore from '../stores/ScreenStore';
import CertificationActivityStore from '../stores/CertificationActivityStore';
import ResourceStore from '../stores/ResourceStore';
import TeamStore from '../stores/TeamStore';
import ReportStore from '../stores/ReportStore';
import AssignmentStore from '../stores/AssignmentStore';
import CoachStore from '../stores/CoachStore';
import AIConversationStore from '../stores/AIConversationStore';

const App = asyncComponent(() => import(/* webpackChunkName: "app" */ './App'));
const Login = asyncComponent(() => import(/* webpackChunkName: "app" */ './Login'));
const LoginBackstage = asyncComponent(() => import(/* webpackChunkName: "app" */ './LoginBackstage'));
const Logout = asyncComponent(() => import(/* webpackChunkName: "app" */ './Logout'));
const LoginBackstageTrugreen = asyncComponent(() => import(/* webpackChunkName: "app" */ './LoginBackstageTrugreen'));
const LoginBackstageGenpact = asyncComponent(() => import(/* webpackChunkName: "app" */ './LoginBackstageGenpact'));
const LoginBackstageTTEC = asyncComponent(() => import(/* webpackChunkName: "app" */ './LoginBackstageTTEC'));

const routes = [
  { path: '/login/backstage' },
  { path: '/trugreen' },
  { path: '/genpact' },
  { path: '/ttec' },
  { path: '/login' },
  { path: '/logout' },
  { path: '/' },
];

const history = createBrowserHistory();

const Root = () => (
  <Provider
    userStore={UserStore}
    languageStore={LanguageStore}
    onboardingStore={OnboardingStore}
    momentStore={MomentStore}
    momentActivityStore={MomentActivityStore}
    simulationStore={SimulationStore}
    simulationActivityStore={SimulationActivityStore}
    notificationsStore={NotificationStore}
    inboxStore={InboxStore}
    certificationStore={CertificationStore}
    screenStore={ScreenStore}
    certificationActivityStore={CertificationActivityStore}
    resourceStore={ResourceStore}
    teamStore={TeamStore}
    reportStore={ReportStore}
    assignmentStore={AssignmentStore}
    coachStore={CoachStore}
    AIConversationStore={AIConversationStore}
  >
    <ThemeSelector>
      <CssBaseline />

      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route
              path="/login/backstage"
              component={LoginBackstage}
            />
            <Route
              path="/trugreen"
              component={LoginBackstageTrugreen}
            />
            <Route
              path="/ttec"
              component={LoginBackstageTTEC}
            />
            <Route
              path="/genpact"
              component={LoginBackstageGenpact}
            />
            <Route
              path="/login"
              component={Login}
            />
            <Route
              path="/logout"
              component={Logout}
            />
            <Route
              path="/"
              component={App}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    </ThemeSelector>
  </Provider>
);

export default Root;
