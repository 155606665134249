import { decorate, observable, action } from 'mobx';
import Ployglot from 'node-polyglot';
import { merge } from 'lodash';

import { defaultLanguageCode, getLanguagePack } from '../../common/lang';

const defaultLanguagePack = getLanguagePack(defaultLanguageCode);

const getMergedLanguagePack = (languageCode) => {
  const languagePack = getLanguagePack(languageCode);
  const merged = {};
  merge(merged, defaultLanguagePack, languagePack);

  return merged;
};

const getPolygotInstance = (languageCode) => {
  const languagePack = languageCode === defaultLanguageCode
    ? defaultLanguagePack
    : getMergedLanguagePack(languageCode);
  const polyglot = new Ployglot({
    locale: languageCode,
    phrases: languagePack,
  });

  return polyglot;
};

class LanguageStore {
  constructor() {
    this.lang = getPolygotInstance('en');
  }

  async setLanguage(languageCode) {
    const code = languageCode || defaultLanguageCode;
    this.lang = getPolygotInstance(code);
  }

  languageCodeToString(languageCode) {
    let language;

    switch (languageCode) {
      case 'en':
        language = 'english';
        break;
      case 'es':
        language = 'spanish';
        break;
      case 'fr':
        language = 'french';
        break;
      default:
        language = 'english';
    }

    return language;
  }
}

decorate(LanguageStore, {
  lang: observable,
  setLanguage: action,
});

export default new LanguageStore();
